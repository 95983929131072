//summit API

export default class Summit {
    
    constructor(){
      //this.apiBase = '/bmsapp/api'
      //this.apiBase = 'http://127.0.0.1:8080/summit2020/api'
      //this.apiBase = 'http://192.168.1.57:8080/summit2020/api'
      //this.apiBase = 'http://213.186.7.4:8080/summit2020/api'
    this.apiBase = 'https://app.bookingmanagersummit.com/api'
    }

    getMessages(room,token){
        const message1 = {id:room};   
        const body1 = JSON.stringify(message1);
        return fetch(this.apiBase+'/getMessagesInRoom',{
          method: 'POST',
          body: body1,
          headers: {'Content-Type': 'application/json',
                'Authorization':token}
          }).then(response => response.json());
    }
}

