<template>  
        <div class="login">
            <div class="login-left">
            <div class="login-logo-desktop"><img src="../../src/assets/images/logo.svg" loading="lazy" alt=""></div>
            <div class="spacer-div"></div>
            <div class="login-footer">
                <div class="contact-header">
                <div class="line"></div>
                <div class="contact">
                    <div class="text-block">CONTACT</div>
                </div>
                <div class="line"></div>
                </div>
                <div class="contact-info">
                <div class="contact-info-in">
                    <a href="tel:+385994773878" class="contact-info-1"><img src="images/phone-icon.svg" loading="lazy" width="30" alt="">
                    <div class="contact-text">
                        <div class="contact-text-inner">+385 99 4773 878</div>
                    </div>
                    </a>
                    <a href="mailto:info@bookingmanagersummit.com" class="contact-info-1"><img src="images/mail-icon.svg" loading="lazy" width="30" alt="">
                    <div class="contact-text">
                        <div class="contact-text-inner">info@bookingmanagersummit.com</div>
                    </div>
                    </a>
                </div>
                </div>
            </div>
            </div>
            <div class="login-right">
            
            <LoginForm></LoginForm>


            <div class="login-logo-mobile"><img src="../../src/assets/images/logo.svg" loading="lazy" width="360" alt=""></div>
            </div>
        </div>
</template>

<script>
import LoginForm from '../components/subcomponents/LoginForm.vue'

export default {
    name: 'Login',
    components: {
        LoginForm
    }
}
</script>

<style>

</style>