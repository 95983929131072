import './assets/js/webflow.js';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  mounted () {

   

    this.$store.dispatch('loginFromCookie');
    this.$store.dispatch('getFavoritesFromCookie');
    this.$store.dispatch('getWorkshops');
    
    setTimeout(() => { this.$store.dispatch('getUsers') }, 500);
    setInterval(() => { this.$store.dispatch('getMessages') }, 1000);
    setInterval(() => { this.$store.dispatch('getWorkshops') }, 60000);
    setInterval(() => { this.$store.dispatch('getUsers') }, 60000);

    if (this.$store.getters.isAuthenticated != true){
      router.replace({ name: "Login" })
    }

    setTimeout(() => {window.Webflow.destroy();window.Webflow.ready(); }, 600);
    //  Webflow.require('ix2').init();    
  },
  render: h => h(App)
}).$mount('#app')
