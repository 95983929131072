<template>
  <div class="partners">
    <div class="partners-content">
      <div class="partners-title-main">
        <h2 class="content-heading">Partners</h2>
      </div>
      <div class="partners-logos">
        <div class="partner-logo"><img src="images/eis.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/bavaria-new.png" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/ncp.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/aci.png" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/acino1-logo.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/fyly.png" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/hilton.png" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/athenian.png" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/hbor.png" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/hgk-partners.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/galic-logo.png" loading="lazy" width="" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>