<template>

   <div> 
    
    <div style="margin-top:50px;margin-left:10px">
        <div class="workshops-content">
            <div class="big-content-div">
                <div class="thankyou-content">
                    <div class="thank-you">
                        <div class="thank-you-title">Welcome {{ name }}!</div>
                        <div class="thank-you-txt">
                            <p>Thank you for joining the <b>5<sup>th</sup> edition of Booking Manager Summit!</b> Let's continue to <i>Shape the Future of Yacht Charter</i> together!</p>
                
                            <p>The goal of this year's conference is creating roadmaps to the future charter industry growth in the aftermath of current global events. 
                            During the Summit, you can now leverage <b>connecting with all of the</b> Summit participants, discuss your current business dilemmas and discover suitable solutions, as well as <b>forge new partnerships</b> or solidify existing ones. 
                            To help you attain your individual business success strategy, you will be able to gather practical knowledge and expertise of <b>over 60 workshops,</b> implementing them into a tailor made solution unique to fit your own business needs.</p>

                            <p>Check the schedule of Booking Manager Summit 2023 and pick the topics best suited to help you achieve clarity for growing your business!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </div>

</template>

<script>
export default {
    name: 'ThankYou',
    computed: {
      name () {
        return !this.$store.getters.user ? false : this.$store.getters.user.name
      }
    },
}
</script>

<style>

</style>